import {orderBy as _orderBy} from 'lodash';
import consts from "../constants/consts";
const dateFormat = require("dateformat");

export const getArticleLinks = (article) => {
    article.category = _orderBy(article.category, ['position'], ['asc']);
    let category = article.category.find(item => !!item.parent) || {};
    if (!category.parent) {
        category = article.category.find(item => item.treeParent !== null || item.position === 1);
        category.parent = category.treeParent
    }
    let topCategory = article.category.find(item => !item.parent);
    const categoryPath = () => {
        if (!category.parent) {
            category = article.category.find(item => item.treeParent !== null || item.position === 1);
            category.parent = category.treeParent
        }
        return `/${category.parent ? category.parent.slug + '/' : ''}${category.slug}`;
    }
    const getReadMore = () => {
        return `${categoryPath()}/${article.slug}`;
    }
    return {
        category,
        categoryPath,
        getReadMore,
        topCategory
    }
}

export const formatDate = (date = new Date(), format='dddd, dS mmmm, yyyy') => {
    return dateFormat(date, format);
}

export const googleScriptLoadingDelay = () => {
    return !document.querySelector('script[src*="gtm"]') ? consts.SCRIPT_LOADING_TIME_WAIT : 1;
    /*return !document.querySelector('script[src*="ad_2.0.1.js"]') ? consts.SCRIPT_LOADING_TIME_WAIT : 1;*/
}

export const isBrowser = () => typeof window !== "undefined";
